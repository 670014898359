import React from 'react';
import { TableHead, TableRow, TableSortLabel, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import './styles.css';
import Tooltip from '../../components/tooltip';
import { InfoRounded as Info } from '@material-ui/icons';

FantasyTableHeader.propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
            key: PropTypes.string.isRequired,
            isSortable: PropTypes.bool.isRequired,
        })
    ),
    onSort: PropTypes.func.isRequired,
};
const MultilineHeader = ({ value }) => (
    <>
        {value[0]}
        <br />
        {value[1]}
    </>
);
function FantasyTableHeader({ headers, onSort, sortBy, sortOrder, ...rest }) {
    const createSortHandler = property => event => {
        onSort(property);
    };
    return (
        <TableHead {...rest}>
            <TableRow classes={{ root: 'header' }}>
                {headers.map(header => (
                    <TableCell
                        key={header.key}
                        align={header.align || 'center'}
                        classes={{ root: 'header-cell' }}
                        sortDirection={sortBy === header.id ? sortOrder : false}
                        style={header.width && { width: header.width, maxWidth: header.width }}
                    >
                        {header.isSortable ? (
                            <TableSortLabel
                                active={sortBy === header.key}
                                direction={sortBy === header.key ? sortOrder : 'asc'}
                                onClick={createSortHandler(header.key)}
                            >
                                {header.multiline ? <MultilineHeader value={header.label} /> : header.label}
                            </TableSortLabel>
                        ) : header.multiline ? (
                            header.tooltip ? (
                                <span className="title-tooltip-box">
                                    <MultilineHeader value={header.label} />
                                    <Tooltip className="tooltip" title={header.tooltip}>
                                        <Info fontSize="small" color="inherit" />
                                    </Tooltip>
                                </span>
                            ) : (
                                <MultilineHeader value={header.label} />
                            )
                        ) : header.tooltip ? (
                            <span className="title-tooltip-box">
                                {header.label}
                                <Tooltip className="tooltip" title={header.tooltip}>
                                    <Info fontSize="small" color="inherit" />
                                </Tooltip>
                            </span>
                        ) : (
                            header.label
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default FantasyTableHeader;
