export const BASE_URL = process.env.REACT_APP_BASE_URL + '/';

export default {
    // Auth based routes
    LOGIN: `${BASE_URL}login`,
    GET_GAMES: `${BASE_URL}games`,
    GET_PLAYERS: `${BASE_URL}players`,
    POST_OPTIMIZE: `${BASE_URL}optimize`,
    GET_OPTIMIZE: `${BASE_URL}optimize`,
    GET_SLATES: `${BASE_URL}slates`,
    GET_LAST_UPDATED: `${BASE_URL}lastUpdated`,
    CONFIG: `${BASE_URL}config`,
};
