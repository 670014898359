import { FormControl, IconButton, MenuItem, Select, TableCell, TableRow } from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../../components/tooltip';
import useInput from '../../hooks/useInput';
import './styles.css';

const regex = /\B(?=(\d{3})+\b)/g;

const captainStr = {
    Fanduel: 'MVP',
    DraftKings: 'Captain',
    Yahoo: 'Superstar',
};

function PlayerTableRow({
    player,
    onExposureChanged,
    onCustomPointChanged,
    onLockChanged,
    min,
    max,
    lockExclude,
    isCaptainMode,
    site,
    customPoints,
    quickCustomScore,
    updateQuickCustomScore,
    permissions,
    toggleMembershipDialog,
    ...rest
}) {
    const {
        id,
        name,
        position,
        team,
        opponent,
        projection,
        salary,
        displayDate,
        /*matchup_projection,*/ ownership,
        value,
    } = player;
    const handleExposure = useInput(onExposureChanged, id);
    const handleLock = useInput(onLockChanged, id);

    //handler for custom points
    const handleCustomPoints = useInput(onCustomPointChanged, id);

    const validateCustomPoints = event => {
        let value = +event.target.value || 0;
        onCustomPointChanged({ key: id, value });
    };

    const handleQuickCustomScoreUp = event => {
        permissions?.playerBoosts
            ? updateQuickCustomScore({ key: id, value: 1 })
            : toggleMembershipDialog('Player Boosts is a premium feature.');
    };
    const handleQuickCustomScoreDown = event => {
        permissions?.playerBoosts
            ? updateQuickCustomScore({ key: id, value: -1 })
            : toggleMembershipDialog('Player Boosts is a premium feature.');
    };

    return (
        <TableRow className="custom-row">
            <TableCell align="center" className="player-cell">
                {position}{' '}
            </TableCell>
            <TableCell align="left" className="player-cell">
                {name}{' '}
            </TableCell>
            <TableCell align="center">
                <ArrowButton
                    rotation={180}
                    onClick={handleQuickCustomScoreDown}
                    baseColor={grey[700]}
                    color={red[500]}
                    value={quickCustomScore}
                />
            </TableCell>
            <TableCell align="center" className="player-cell">
                <ArrowButton
                    rotation={0}
                    onClick={handleQuickCustomScoreUp}
                    baseColor={grey[700]}
                    color={green[500]}
                    value={quickCustomScore}
                />
            </TableCell>
            <TableCell align="center" className="player-cell">
                {team}{' '}
            </TableCell>
            <TableCell align="left" className="player-cell">
                {displayDate}{' '}
            </TableCell>
            <TableCell align="center" className="player-cell">
                {opponent}{' '}
            </TableCell>
            <TableCell align="center" className="player-cell">
                {projection.toFixed(1)}{' '}
            </TableCell>
            <TableCell
                align="center"
                className="player-cell"
                onClick={() =>
                    !permissions?.customPoints ? toggleMembershipDialog('Custom Points is a premium feature.') : null
                }
            >
                <Tooltip
                    disableHoverListener={permissions?.customPoints}
                    disableFocusListener={permissions?.customPoints}
                    title={'Custom Points is a premium feature.'}
                >
                    <input
                        className="exposure-field"
                        key={id}
                        disabled={!permissions?.customPoints}
                        value={customPoints}
                        name="customPoints"
                        onBlur={validateCustomPoints}
                        onChange={handleCustomPoints}
                    ></input>
                </Tooltip>
            </TableCell>
            <TableCell align="center" className="player-cell">
                {'$' + salary.toString().replace(regex, ',')}{' '}
            </TableCell>
            <TableCell align="center" className="player-cell">
                {value.toFixed(2)}{' '}
            </TableCell>
            <TableCell align="center" className="player-cell">
                {ownership === 0 ? '-' : ownership.toFixed(1) + '%'}{' '}
            </TableCell>

            <TableCell align="center">
                <input name="min" type="text" className="exposure-field" onChange={handleExposure} value={min} />
            </TableCell>
            <TableCell align="center" className="body-cell">
                <input name="max" type="text" className="exposure-field" onChange={handleExposure} value={max} />
            </TableCell>

            <TableCell align="center">
                <FormControl>
                    <Select
                        value={lockExclude}
                        onChange={handleLock}
                        fullWidth
                        disableUnderline
                        margin="dense"
                        style={{ fontSize: '0.875rem' }}
                    >
                        <MenuItem value="0">Auto</MenuItem>
                        <MenuItem value="-1">Exclude</MenuItem>
                        <MenuItem value="1">Lock</MenuItem>
                        {isCaptainMode && <MenuItem value="2">{captainStr[site]}</MenuItem>}
                    </Select>
                </FormControl>
            </TableCell>
        </TableRow>
    );
}

PlayerTableRow.propTypes = {
    index: PropTypes.number,
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleLock: PropTypes.func,
    handleMax: PropTypes.func,
    handleMin: PropTypes.func,
    isCaptainMode: PropTypes.bool.isRequired,
    site: PropTypes.string.isRequired,
    player: PropTypes.shape({
        id: PropTypes.number,
        position: PropTypes.string,
        name: PropTypes.string,
        team: PropTypes.string,
        opponent: PropTypes.string,
        points: PropTypes.number,
        salary: PropTypes.number,
        projection: PropTypes.number,
        value: PropTypes.number,
    }),
};

const ArrowButton = ({ value, onClick, rotation, color, baseColor }) => (
    <IconButton onClick={onClick} size="small">
        <svg
            id="Layer_1"
            x="0px"
            y="0px"
            width="24px"
            height="24px"
            viewBox="181.5 0 200 200"
            enable-background="new 181.5 0 200 200"
            transform={`rotate(${rotation || 0})`}
        >
            <path
                fill={value * (rotation ? -1 : 1) > 0 ? color : baseColor}
                d="M243.25,200l38.25-38.166L319.75,200l11.75-11.75l-50-50l-50,50L243.25,200z"
            />
            <path
                fill={value * (rotation ? -1 : 1) > 1 ? color : baseColor}
                d="M243.25,130.875l38.25-38.166l38.25,38.166l11.75-11.75l-50-50l-50,50L243.25,130.875z"
            />
            <path
                fill={value * (rotation ? -1 : 1) > 2 ? color : baseColor}
                d="M243.25,61.75l38.25-38.166l38.25,38.166L331.5,50l-50-50l-50,50L243.25,61.75z"
            />
        </svg>
    </IconButton>
);

export default React.memo(PlayerTableRow);

/**
 * @todo Add these back when we have matchup and ownership.
                <TableCell align="center" className='player-cell'>{matchup_projection}</TableCell>
            <TableCell align="center" className='player-cell'>{ownership}		 </TableCell>
 */
