import { useCallback, useEffect } from 'react';

export default function useGoogleAnalytics(page) {
    useEffect(() => {
        window.gtag('config', process.env.REACT_APP_GTAG_ID, { page_path: page });
    }, [page]);

    const reportLineups = useCallback((lineupCount, site) => {
        window.gtag('event', 'Optimize', { event_category: 'lineups', event_label: site, value: lineupCount });
    }, []);

    const reportDownloads = useCallback((site, lineups) => {
        window.gtag('event', 'CSV Export', { event_category: 'export', event_label: site, value: lineups });
    }, []);

    return { reportLineups, reportDownloads };
}
