const { useCallback } = require('react');
/**
 * Effect for extracting data from input events, and calling the handler.
 * @param {Function} func
 */
function useInput(func, key) {
    const onInputChanged = useCallback(
        (e, inputValue) => {
            let { name, value } = e.target;
            value = value || inputValue;
            func({ name, value, key });
        },
        [func, key]
    );
    return onInputChanged;
}

export default useInput;
