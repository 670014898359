import axiosCall from './index';
import types from './action-types';
import URL from '../../config/routes';

/**
 * Get a list of games.
 * @param {number} id queue id (optional)
 */
export function getLastOptimizedTeam(id = '') {
    const path = URL.GET_OPTIMIZE;
    const responseType = types.OPTIMIZE;
    return axiosCall('get', path, responseType, { id });
}
