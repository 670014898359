import React, { useEffect, useCallback } from 'react';
import { Grid, Button } from '@material-ui/core';
import SetupForm from './setupForm';
import data from './formData';
import GamesListView from './gameCard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    getGames,
    getSlates,
    optimizeLineup,
    saveConfig,
    loadConfig,
    loadAllConfigs,
    loadConfigById,
} from '../../redux/actions/setup.action';
import { useSnackbar } from 'notistack';
import { updateForm, updateBatchForm, resetCustomPointAndStack } from '../../redux/actions/form.action';
import { useOptimize } from '../../hooks/useOptimize';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';
import usePermissions from '../../hooks/usePermissions';

function LineupSetup({
    games,
    form,
    excludeTeams,
    loadConfig,
    config,
    saveConfig,
    loadAllConfigs,
    loadConfigById,
    updateForm,
    getGames,
    updateBatchForm,
    optimizeLineup,
    isOptimizing,
    slates,
    getSlates,
    resetCustomPointAndStack,
    ...props
}) {
    const { reportLineups } = useGoogleAnalytics('/optimizer/setup');
    const { enqueueSnackbar } = useSnackbar();
    const permissions = usePermissions();
    const { site, isCaptainMode, gameFilter } = form;
    const {
        config: { site: configSite, isCaptainMode: configCaptainMode },
    } = config;

    //Callback method for validating and submitting optimization options.
    const onClick = React.useCallback(
        e => {
            optimizeLineup(form, {}, [], permissions);
            reportLineups(+form.lineups);
        },
        [form, optimizeLineup, reportLineups, permissions]
    );

    useOptimize(isOptimizing, props.history);

    //Navigate to advnaced player settings.
    const onClickAdvanceSettings = React.useCallback(
        e => {
            props.history.push('/optimizer/player-settings');
        },
        [props.history]
    );

    const onSaveConfig = React.useCallback(
        configName => {
            saveConfig(configName, form, {}, [], permissions);
        },
        [form, saveConfig, permissions]
    );

    const {
        query: { site: gameSite, isCaptainMode: gameCaptainMode, slate: gameSlate },
        isSuccess: gameSuccess,
        isLoading: gameIsLoading,
    } = games;

    const {
        query: { site: slateSite, isCaptainMode: slateCaptainMode },
        isSuccess: slateSuccess,
        isLoading: slateIsLoading,
    } = slates;

    //Get slates
    useEffect(() => {
        if (slateIsLoading) return;
        if (site === slateSite && isCaptainMode === slateCaptainMode && slateSuccess) return;
        getSlates(site, isCaptainMode);
    }, [site, isCaptainMode, slateSite, slateCaptainMode, slateSuccess, getSlates, gameFilter, slateIsLoading]);

    //Fetch the list of games
    useEffect(() => {
        if (gameFilter === '' || gameIsLoading || (isCaptainMode && gameFilter === 'Main')) return;
        if (
            site.toLowerCase() === gameSite &&
            isCaptainMode === gameCaptainMode &&
            gameFilter === gameSlate &&
            gameSuccess
        )
            return;
        getGames(site.toLowerCase(), isCaptainMode, gameFilter);
    }, [site, isCaptainMode, gameFilter, getGames, gameSite, gameCaptainMode, gameSlate, gameSuccess, gameIsLoading]);

    //Load the config when site and captain mode changes.
    useEffect(() => {
        if (site === configSite && isCaptainMode === configCaptainMode) return;
        loadConfig(site, isCaptainMode);
    }, [site, isCaptainMode, loadConfig, configSite, configCaptainMode]);

    //Error handling
    useEffect(() => {
        const errors = form.error;
        if (errors) {
            errors.form.forEach(error => {
                enqueueSnackbar(error.msg, {
                    variant: 'error',
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom',
                    },
                });
            });
            if (errors.players?.length) {
                enqueueSnackbar(`Player settings has ${errors.players.length}.`, {
                    variant: 'error',
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom',
                    },
                    action: key => (
                        <Button onClick={onClickAdvanceSettings} color="inherit">
                            Fix
                        </Button>
                    ),
                });
            }
        }
    }, [form.error, onClickAdvanceSettings, enqueueSnackbar]);

    //Callback method for updating excluded teams.
    const toggleTeam = useCallback(
        team => {
            updateForm({ name: 'excludeTeams', value: { ...excludeTeams, [team]: !!!excludeTeams[team] } });
        },
        [excludeTeams, updateForm]
    );

    const { saveTS } = config;
    useEffect(() => {
        loadAllConfigs(site, isCaptainMode);
    }, [saveTS, loadAllConfigs, site, isCaptainMode]);

    return (
        <Grid container style={{ padding: '1rem', maxWidth: '100%' }} spacing={1}>
            <Grid item xs={12} md={4}>
                <SetupForm
                    onChange={updateForm}
                    onBatchChange={updateBatchForm}
                    inputData={data}
                    form={form}
                    onClickOptimize={onClick}
                    onClickAdvanceSettings={onClickAdvanceSettings}
                    slates={slates.res}
                    resetCustomPointAndStack={resetCustomPointAndStack}
                    onSaveConfig={onSaveConfig}
                    config={config}
                    loadConfig={loadConfigById}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                {
                    <GamesListView
                        games={games.res}
                        isLoading={games.isLoading}
                        excludeTeams={excludeTeams}
                        slate={form.gameFilter}
                        onToggleTeam={toggleTeam}
                    />
                }
            </Grid>
        </Grid>
    );
}

const mapStateToProps = state => {
    const form = state.Form;
    return {
        config: state.Config,
        games: state.Games,
        slates: state.Slates,
        form,
        excludeTeams: form.excludeTeams,
        isOptimizing: state.Optimize.isLoading,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getGames,
            updateForm,
            optimizeLineup,
            getSlates,
            updateBatchForm,
            resetCustomPointAndStack,
            saveConfig,
            loadConfig,
            loadAllConfigs,
            loadConfigById,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LineupSetup);
