import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { updateStacks } from '../../redux/actions/form.action';
import { getPlayers } from '../../redux/actions/setup.action';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FantasyButton } from '../fatnasyButton';

import Stack from './Stack';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.spacing(2),
        padding: theme.spacing(1),
        width: '80%',
        height: '500px',
    },
    checkboxLabel: {
        fontSize: '0.75rem',
    },
    input: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    stackTitleRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    addButton: {
        marginTop: theme.spacing(2),
        alignContent: 'center',
        width: 150,
    },
}));

const CustomStackDialog = props => {
    const {
        form,
        getPlayers,
        isLoading,
        onToggle,
        isOpen,
        stateKey,
        allPlayers: players,
        site,
        updateStacks,
        slate,
        ...rest
    } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [expanded, setExpanded] = React.useState(-1);
    const [stacks, setStacks] = React.useState((form.stacks || []).slice(0));
    const classes = useStyles();

    //Fetch the player data based on the slate and site selected.
    useEffect(() => {
        if (!form.gameFilter) {
            //history.push('/optimizer/setup')
        } else {
            getPlayers(form.site.toLowerCase(), form.isCaptainMode, form.gameFilter);
        }
    }, [site, slate, form.gameFilter, form.site, form.isCaptainMode, getPlayers]);

    const handleStackToggle = index => (event, isExpanded) => {
        setExpanded(isExpanded ? index : false);
    };

    const handleWeightChanged = index => e => {
        const stack = { ...stacks[index] };
        stack.weight = e.target.value;
        const newStacks = stacks.slice(0);
        newStacks[index] = stack;
        setStacks(newStacks);
    };

    const handleKeyPlayerChanged = index => (e, value) => {
        const stack = { ...stacks[index] };
        stack.keyPlayer = value;
        const newStacks = stacks.slice(0);
        newStacks[index] = stack;
        setStacks(newStacks);
    };

    const handleIncludePlayer = index => (e, players) => {
        const stack = { ...stacks[index] };
        stack.included = players;
        const newStacks = stacks.slice(0);
        newStacks[index] = stack;
        setStacks(newStacks);
    };

    const handleExcludePlayer = index => (e, players) => {
        const stack = { ...stacks[index] };
        stack.excluded = players;
        const newStacks = stacks.slice(0);
        newStacks[index] = stack;
        setStacks(newStacks);
    };

    const handleAddStack = useCallback(() => {
        setStacks(stacks => {
            if (stacks.length < 25) {
                const result = stacks.slice(0);
                result.push({
                    keyPlayer: undefined,
                    included: [],
                    excluded: [],
                    weight: Math.max(100 - (stacks.reduce((prev, curr) => +curr.weight + prev, 0) || 0), 0),
                });
                setStacks(result);
            } else {
                enqueueSnackbar('Maximum stack limit reached.', {
                    variant: 'error',
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom',
                    },
                });
                setStacks(stacks);
            }
        });
    }, [setStacks, enqueueSnackbar]);

    const handleDeleteStack = index => e => {
        e.stopPropagation();

        const newStacks = stacks.slice(0);
        newStacks.splice(index, 1);
        setStacks(newStacks);
    };

    useLayoutEffect(() => {
        setStacks(stacks => {
            if (!stacks.length)
                return [
                    {
                        keyPlayer: undefined,
                        included: [],
                        excluded: [],
                        weight: 100,
                    },
                ];
            return stacks;
        });
    }, [setStacks, form]);

    const onSubmit = useCallback(() => {
        //Clean up stacks
        //const weight = stacks.reduce((cum,cur)=>(+cur.weight)+cum,0);
        // const newStack = stacks.map(stack => {
        //     return {
        //         include: (stack.keyPlayer ? [stack.keyPlayer] : []).concat(stack.included).map(player => player.id),
        //         exclude: stack.excluded.map(player => player.id),
        //         weight: stack.weight,
        //     };
        // });
        let error;

        // if(stacks.length && ( weight !== 100)){
        //     error = 'Custom stack weight must sum to 100%.';
        // } else
        if (stacks.some(stack => +stack.weight < 0)) {
            error = 'Custom stack weight can not be negative.';
        } else if (stacks.some(stack => stack.weight === '0' || stack.weight === '')) {
            error = 'Please assign weights to all stacks.';
        } else if (stacks.some(stack => !stack?.keyPlayer?.id)) {
            error = 'Please select a key player for all stacks.';
        } else if (stacks.some(stack => stack?.included.length + stack?.excluded.length === 0)) {
            error = 'Please select at least one player to stack with the key player for all stacks.';
        }

        if (error) {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom',
                },
            });
            return;
        }

        updateStacks({ value: stacks });
        onToggle();
    }, [stacks, updateStacks, onToggle, enqueueSnackbar]);

    return (
        <Dialog open={isOpen} fullWidth maxWidth="sm" classes={{ paper: classes.root }} {...rest}>
            <DialogTitle>
                <div className={classes.stackTitleRow}>
                    Custom Stack
                    <FantasyButton variant="outlined" label="Add Another Stack" onClick={handleAddStack} />
                </div>
            </DialogTitle>
            <DialogContent>
                {isLoading && 'Please Wait...'}
                {!isLoading &&
                    stacks.map((stack, index) => (
                        <Stack
                            index={index}
                            key={index}
                            excluded={stack.excluded}
                            included={stack.included}
                            weight={stack.weight}
                            keyPlayer={stack.keyPlayer}
                            onKeyPlayerChange={handleKeyPlayerChanged(index)}
                            onExcludePlayer={handleExcludePlayer(index)}
                            onIncludePlayer={handleIncludePlayer(index)}
                            onToggleStack={handleStackToggle(index)}
                            expanded={expanded}
                            players={players}
                            onWeightChanged={handleWeightChanged(index)}
                            onDelete={handleDeleteStack(index)}
                        />
                    ))}
            </DialogContent>
            <DialogActions>
                <Grid container justify="space-evenly">
                    <Grid item xs={5}>
                        <FantasyButton label="Cancel" variant="outlined" fullWidth onClick={onToggle} />
                    </Grid>
                    <Grid item xs={5}>
                        <FantasyButton label="Okay" variant="contained" onClick={onSubmit} fullWidth />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = state => {
    const { isLoading, isSuccess, isError, players, site, slate } = state.Player;
    return {
        form: state.Form,
        allPlayers: players || [],
        isLoading,
        isSuccess,
        isError,
        site,
        slate,
        isOptimizing: state.Optimize.isLoading,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getPlayers, updateStacks }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomStackDialog);
