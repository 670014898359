import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid } from '@material-ui/core';
import { FantasyButton } from '../fatnasyButton';

function MembershipTierDialog({ title, message, isOpen, onCancel }) {
    const subscribeNow = () => {
        window.open(process.env.REACT_APP_SUBSCRIPTION_URL);
    };
    return (
        <Dialog
            open={isOpen}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            keepMounted={false}
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: 'flex}', justifyContent: 'space-between', margin: '0 12px 12px 12px' }}>
                <Grid item xs={5}>
                    <FantasyButton onClick={onCancel} variant="outlined" label="Cancel" fullWidth={true} />
                </Grid>
                <Grid item xs={5} style={{ position: 'relative' }}>
                    <FantasyButton onClick={subscribeNow} variant="contained" label="Subscribe Now" fullWidth={true} />
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default MembershipTierDialog;
