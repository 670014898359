import types from '../actions/action-types';

const DEFAULT_STATE = {
    isError: false,
    isLoading: false,
    query: {},
    res: [],
    isSuccess: false,
};
export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case `${types.SLATES}_LOADING`:
            return {
                isError: false,
                isLoading: true,
                query: { ...action.updatePayload },
                res: [],
            };
        case `${types.SLATES}_REJECTED`:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.updatePayload.res,
                isError: true,
            };
        case `${types.SLATES}_SUCCESS`:
            return {
                ...state,
                isSuccess: true,
                isLoading: false,
                res: action.updatePayload.res,
                isError: false,
            };

        default:
            return state;
    }
};
