import { combineReducers } from 'redux';
import Auth from './auth.reducer';
import Player from './player.reducer';
import Games from './games.reducer';
import Form from './form.reducer';
import Optimize from './optimize.reducer';
import Slates from './slates.reducer';
import Config from './config.reducer';

const rootReducer = combineReducers({
    Auth,
    Player,
    Games,
    Form,
    Optimize,
    Slates,
    Config,
});

export default rootReducer;
