import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    TextField,
    makeStyles,
    Grid,
    FormLabel,
    Slider,
    Dialog,
    DialogTitle,
    Container,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Checkbox,
    Typography,
    InputAdornment,
} from '@material-ui/core';

import { FantasyButton } from '../fatnasyButton';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.spacing(2),
        padding: theme.spacing(1),
    },
    checkboxLabel: {
        fontSize: '0.75rem',
    },
    input: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

const DEFAULT_VALUES = {
    GLOBAL: 30,
    QB: 30,
    WR: 30,
    RB: 30,
    TE: 30,
    DST: 30,
    custom: false,
};

const POSITIONS = ['QB', 'RB', 'WR', 'TE', 'DST'];

const RandomizerDialog = props => {
    const { initialValues = DEFAULT_VALUES, onDone, onCancel, isOpen, title, stateKey, min, max, ...rest } = props;

    const classes = useStyles();

    const [values, setValues] = useState(initialValues);

    useEffect(() => {
        if (isOpen) setValues(initialValues);
    }, [setValues, isOpen, initialValues]);

    const inputHandlers = useMemo(() => {
        const onSliderChanged = pos => (e, value) => {
            setValues(values => ({ ...values, [pos]: value }));
        };
        return {
            input: e => {
                const { name, value } = e.target;
                const validatedValue = Math.min(+value, max);
                setValues(values => ({ ...values, [name]: validatedValue }));
            },
            inputBlur: e => {
                const { name, value } = e.target;
                const validatedValue = Math.min(Math.max(+value, min), max);
                setValues(values => ({ ...values, [name]: validatedValue }));
            },
            sliderQB: onSliderChanged('QB'),
            sliderRB: onSliderChanged('RB'),
            sliderWR: onSliderChanged('WR'),
            sliderTE: onSliderChanged('TE'),
            sliderDST: onSliderChanged('DST'),
            sliderGlobal: onSliderChanged('GLOBAL'),
            checkbox: e => {
                const { name, checked } = e.target;
                setValues(values => ({ ...values, [name]: checked }));
            },
        };
    }, [setValues, max, min]);

    const onSubmit = useCallback(() => {
        onDone({
            [stateKey]: values,
        });
        onCancel();
    }, [values, onDone, onCancel, stateKey]);

    return (
        <Dialog open={isOpen} fullWidth maxWidth="xs" {...rest} classes={{ paper: classes.root }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Container fixed maxWidth="md" disableGutters className={classes.root}>
                    <Grid container alignItems="center">
                        <Grid item xs={8}>
                            <span>
                                <FormLabel className={classes.checkboxLabel} id="discrete-slider-restrict">
                                    {' '}
                                    All Positions{' '}
                                </FormLabel>
                            </span>
                            <Slider
                                defaultValue={1}
                                name={`GLOBAL`}
                                aria-labelledby="discrete-slider-restrict"
                                min={min}
                                max={max}
                                value={values.GLOBAL}
                                disabled={values.custom}
                                valueLabelDisplay="auto"
                                draggable={true}
                                style={{ width: '95%', marginLeft: '2.5%' }}
                                color="secondary"
                                onChange={inputHandlers.sliderGlobal}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.input}>
                            <TextField
                                name={`GLOBAL`}
                                disabled={values.custom}
                                label="All Positions"
                                type="number"
                                variant="outlined"
                                size="small"
                                value={values.GLOBAL}
                                onChange={inputHandlers.input}
                                onBlur={inputHandlers.inputBlur}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography>%</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.custom}
                                    onChange={inputHandlers.checkbox}
                                    name="custom"
                                    color="primary"
                                />
                            }
                            labelPlacement="end"
                            label="Customize by Position"
                        />
                    </Grid>
                    {POSITIONS.map(pos => (
                        <Grid container key={pos} alignItems="center">
                            <Grid item xs={8}>
                                <span>
                                    <FormLabel className={classes.checkboxLabel} id="discrete-slider-restrict">
                                        {' '}
                                        {pos}{' '}
                                    </FormLabel>
                                </span>
                                <Slider
                                    defaultValue={1}
                                    name={pos}
                                    aria-labelledby="discrete-slider-restrict"
                                    step={1}
                                    disabled={!values.custom}
                                    min={min}
                                    max={max}
                                    value={values[pos]}
                                    valueLabelDisplay="auto"
                                    draggable={true}
                                    style={{ width: '95%', marginLeft: '2.5%' }}
                                    color="secondary"
                                    onChange={inputHandlers[`slider${pos}`]}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.input}>
                                <TextField
                                    name={pos}
                                    label={pos}
                                    type="number"
                                    disabled={!values.custom}
                                    variant="outlined"
                                    size="small"
                                    value={values[pos]}
                                    onChange={inputHandlers.input}
                                    onBlur={inputHandlers.inputBlur}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography>%</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Container>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={12} justify="space-evenly">
                    <Grid item xs={5} justify="center">
                        <FantasyButton label="Cancel" variant="outlined" fullWidth onClick={onCancel} />
                    </Grid>
                    <Grid item xs={5}>
                        <FantasyButton label="Okay" variant="contained" onClick={onSubmit} fullWidth />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default RandomizerDialog;
