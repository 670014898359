import { useCallback, useEffect, useState } from 'react';
import { AuthWatcher } from '../services/auth';

export default function usePermissions() {
    const [permissions, setPermissions] = useState({});

    const updatePermissions = useCallback(() => {
        const token = localStorage.getItem('token');
        try {
            if (token) {
                const payload = token.split('.')[1];
                const claims = JSON.parse(Buffer.from(payload, 'base64'));
                setPermissions(claims?.permissions || {});
            }
        } catch (e) {
            console.log(e);
        }
    }, [setPermissions]);

    useEffect(() => {
        updatePermissions();
        AuthWatcher.subscribe(updatePermissions);
        return () => {
            AuthWatcher.unsubscribe(updatePermissions);
        };
    }, [updatePermissions]);

    return permissions;
}
