import types from '../actions/action-types';

export default (state = [], action) => {
    switch (action.type) {
        case `${types.LOGIN}_LOADING`:
            return { isLoading: true };
        case `${types.LOGIN}_REJECTED`:
            return {
                ...state,
                isLoading: false,
                loginResponse: action.updatePayload,
            };

        default:
            return state;
    }
};
