import React, { useCallback, useEffect, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Container,
    makeStyles,
    TextField,
    CircularProgress,
    MenuItem,
} from '@material-ui/core';
import { FantasyButton } from '../fatnasyButton';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.spacing(2),
        padding: theme.spacing(1),
    },
    descriptionText: {
        color: '#0000008a',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        bottom: '50%',
        left: '0.5rem',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
}));

const LoadConfigDialog = ({
    isOpen,
    onCancel,
    configs = [],
    loadConfig,
    isLoading = true,
    isError,
    errorMessage,
    ...rest
}) => {
    const ref = useRef(isLoading);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [config, setConfig] = React.useState(configs.length > 0 ? configs[0].id : '');

    const handleConfigChanged = useCallback(
        e => {
            setConfig(e.target.value);
        },
        [setConfig]
    );

    const handleLoad = useCallback(() => {
        if (config === '') {
            enqueueSnackbar('Please select a configuration', {
                variant: 'error',
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom',
                },
            });
            return;
        }
        ref.current = true;
        loadConfig(config);
    }, [config, loadConfig, enqueueSnackbar]);

    useEffect(() => {
        if (ref.current === isLoading) return;
        if (ref.current) {
            if (isError) {
                enqueueSnackbar(errorMessage, { variant: 'error' });
            } else {
                enqueueSnackbar('Saved configuration loaded successfully.', {
                    variant: 'success',
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom',
                    },
                });
                onCancel();
            }
            ref.current = false;
        }
    }, [isLoading, isError, errorMessage, enqueueSnackbar, onCancel]);

    useEffect(() => {
        setConfig(configs.length > 0 ? configs[0].id : '');
    }, [configs]);

    return (
        <Dialog open={isOpen} fullWidth maxWidth="xs" {...rest} classes={{ paper: classes.root }}>
            <DialogTitle>Load Saved Setup</DialogTitle>
            <DialogContent>
                <Container fixed maxWidth="md" disableGutters className={classes.root}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <DialogContentText className={classes.descriptionText}>
                                Load a previously saved configuration
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Configuration Name"
                                variant="outlined"
                                onChange={handleConfigChanged}
                                select
                                value={config}
                            >
                                {configs.map((config, index) => (
                                    <MenuItem key={index} value={config.id}>
                                        {config.name} - {new Date(config.created).toLocaleString()}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={12} justify="space-evenly">
                    <Grid item xs={5} justify="center">
                        <FantasyButton
                            label="Cancel"
                            variant="outlined"
                            fullWidth
                            onClick={onCancel}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={5} style={{ position: 'relative' }}>
                        <FantasyButton
                            label="Okay"
                            variant="contained"
                            onClick={handleLoad}
                            fullWidth
                            disabled={isLoading}
                        />
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default LoadConfigDialog;
