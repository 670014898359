import React from 'react';
import LoginForm from '../../components/login';
import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from '../../redux/actions/auth.action';
import { useEffect } from 'react';
import { AuthContext } from '../../firebase/Auth';
import { useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';

const EMAIL_PATTERN =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const action = key => (
    <Button
        style={{ color: 'white' }}
        onClick={() => {
            window.open('https://www.fantasypoints.com/subscribe');
        }}
    >
        Subscribe
    </Button>
);

function Login(props) {
    const [form, setForm] = useState({ email: '', password: '' });
    const [error, setError] = useState();
    const { currentUser } = React.useContext(AuthContext);
    const { history, loginResponse } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    //Redirect to setup if we are already logged in.
    useEffect(() => {
        if (currentUser) {
            history.push('/optimizer/setup');
        }
    }, [currentUser, history]);

    //Handle the errors.
    useEffect(() => {
        if (loginResponse?.isError === true) {
            enqueueSnackbar(loginResponse.res.msg, {
                key: 'auth-error',
                variant: 'error',
                anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'bottom',
                },
                autoHideDuration: 10000,
                action,
            });
        }
    }, [loginResponse, enqueueSnackbar, action]);

    /**
     * Handles the submit button click event.
     * @param {import('react').SyntheticEvent} e
     */
    function onSubmit(e) {
        e.preventDefault();
        closeSnackbar();
        const error = {};
        if (!EMAIL_PATTERN.test(form.email)) {
            error.email = 'Please enter a valid email address.';
        }
        if (!form.password?.trim().length) {
            error.password = 'Please enter a valid password.';
        }
        if (error.email || error.password) {
            setError(error);
        } else {
            setError();
            props.login(form);
        }
        //Validate
        //Submit
    }

    /**
     * Handle the forgot password click action.
     */
    function onForgotPassword() {
        window.location.href = 'https://www.fantasypoints.com/forgot-password';
    }

    /**
     * Handles the register button click event.
     */
    function onRegister() {
        window.location.href = 'https://www.fantasypoints.com/subscribe';
    }

    return (
        <LoginForm
            onInputChange={setForm}
            email={form.email}
            password={form.password}
            onRegister={onRegister}
            onSubmit={onSubmit}
            onForgotPassword={onForgotPassword}
            error={error}
            isLoading={props.isLoading}
        />
    );
}

const mapStateToProps = state => {
    return {
        isLoading: state.Auth.isLoading,
        loginResponse: state.Auth.loginResponse,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ login }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
