import types from './action-types';
const responseType = types.FORM;

export function updateForm(form) {
    return {
        type: `${responseType}_UPDATE`,
        updatePayload: form,
    };
}

export function updateBatchForm(form) {
    return {
        type: `${responseType}_UPDATE_BATCH`,
        updatePayload: form,
    };
}

export function resetPlayers(form) {
    return {
        type: `${responseType}_RESET_PLAYERS`,
    };
}

export function updateExposure({ name, value, key }) {
    return {
        type: `${responseType}_EXPOSURE`,
        updatePayload: { name, value, key },
    };
}

export function updateLockExclude({ name, value, key }) {
    return {
        type: `${responseType}_LOCK_EXCLUDE`,
        updatePayload: { name, value, key },
    };
}

export function updateCustomPoint({ name, value, key }) {
    return {
        type: `${responseType}_UPDATE_CUSTOM`,
        updatePayload: { name, key, value },
    };
}

export function resetCustomPointAndStack() {
    return {
        type: `${responseType}_RESET_CUSTOM_POINTS_AND_STACK`,
    };
}

export function updateQuickCustomScore(updatePayload) {
    return {
        type: `${responseType}_UPDATE_QUICK_CUSTOM_SCORE`,
        updatePayload,
    };
}

export function updateStacks(updatePayload) {
    return {
        type: `${responseType}_UPDATE_STACKS`,
        updatePayload,
    };
}
