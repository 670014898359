export const sites = [
    //    { value: 'Yahoo',   label: 'Yahoo' },
    { value: 'DraftKings', label: 'Draft Kings' },
    { value: 'Fanduel', label: 'Fan Duel' },
];

export const qbStacks = [
    { value: false, label: 'No' },
    { value: 'WR', label: 'With WR' },
    { value: 'TE', label: 'With TE' },
];
export const rbStacks = [
    { value: false, label: 'No' },
    { value: 'DST', label: 'With DST' },
];

export const captainModeTitle = {
    Fanduel: 'Single Game',
    DraftKings: 'Showdown Captain Mode',
    Yahoo: 'Single Game',
};

export const captainModeOptions = [
    { value: false, label: 'No' },
    { value: true, label: 'Yes' },
];

export const diversity = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
];

export default { sites, qbStacks, rbStacks, diversity, captainModeTitle, captainModeOptions };
