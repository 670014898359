import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Typography, makeStyles } from '@material-ui/core';
import { InfoRounded as Info } from '@material-ui/icons';
import clsx from 'clsx';
import Tooltip from '../tooltip';

const useStyles = makeStyles(theme => ({
    whiteText: {
        color: 'white',
    },
    title: {
        color: '#ffffff',
        fontFamily: 'Roboto',
        fontSize: '1rem !important',
        fontWeight: 700,
    },
    cardHeader: {
        backgroundColor: '#cc3333',
        paddingTop: '0.66rem !important',
        paddingBottom: '0.66rem !important',
    },
    cardHeaderTitle: {
        display: 'flex',
        flexDirection: 'row',
    },
    card: {
        borderRadius: '10px',
    },
    cardContent: {
        padding: '0px !important',
    },
    infoIcon: {
        verticalAlign: 'middle',
        color: 'white',
    },
    tooltip: {
        marginLeft: theme.spacing(1),
    },
}));

function BaseCard({ title, action, children, className = '', titleClass = '', baseClass, tooltip, ...rest }) {
    const classes = useStyles();

    return (
        <Card className={clsx(classes.card, baseClass)} raised={true} {...rest}>
            <CardHeader
                className={classes.cardHeader}
                title={
                    <span style={{ display: 'flex', flexDirection: 'row' }} className={titleClass}>
                        <Typography className={clsx(classes.title, classes.cardHeaderTitle)}>{title}</Typography>
                        {tooltip && (
                            <Tooltip title={tooltip} className={classes.tooltip}>
                                <Info fontSize="small" className={classes.infoIcon} color="inherit" />
                            </Tooltip>
                        )}
                    </span>
                }
                action={action}
            ></CardHeader>

            <CardContent className={`${classes.cardContent} ${className}`} {...rest}>
                {children}
            </CardContent>
        </Card>
    );
}

BaseCard.propTypes = {
    title: PropTypes.string.isRequired,
    action: PropTypes.node,
    tooltip: PropTypes.string,
};
export default BaseCard;
