import React, { useState, useCallback } from 'react';
import BaseCard from '../../components/baseCard';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import {
    TableContainer,
    Table,
    TableBody,
    makeStyles,
    TableRow,
    TableFooter,
    TablePagination,
    Button,
    withStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';
import FilterRow from './filterRow';
import useSorting from '../../hooks/useSorting';
import headers from './header';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPlayers, optimizeLineup } from '../../redux/actions/setup.action';
import PropTypes from 'prop-types';
import FantasyTableHeader from '../../components/fantasyTableHeader';
import PlayerTableRow from './playerTableRow';
import { AutoSizer } from 'react-virtualized';
import {
    updateForm,
    updateExposure,
    updateCustomPoint,
    updateLockExclude,
    resetPlayers,
    updateQuickCustomScore,
} from '../../redux/actions/form.action';
import ContentLoader from 'react-content-loader';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { useOptimize } from '../../hooks/useOptimize';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';
import MembershipTierDialog from '../../components/membershipTierDialog/membershipTierDialog';
import usePermissions from '../../hooks/usePermissions';

const tooltip = `Ownership percentages usually do not arrive until at least Thursday.`;
AdvanceSettings.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    isError: PropTypes.bool.isRequired,
    players: PropTypes.shape({
        Yahoo: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            position: PropTypes.string.isRequired,
            opponent: PropTypes.string.isRequired,
            gameDate: PropTypes.string.isRequired,
            salary: PropTypes.number,
            projection: PropTypes.number,
            matchup_projection: PropTypes.number.isRequired,
            ownership: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
        }),
        DraftKings: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            position: PropTypes.string.isRequired,
            opponent: PropTypes.string.isRequired,
            gameDate: PropTypes.string.isRequired,
            salary: PropTypes.number,
            projection: PropTypes.number,
            matchup_projection: PropTypes.number.isRequired,
            ownership: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
        }),
        Fanduel: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            position: PropTypes.string.isRequired,
            opponent: PropTypes.string.isRequired,
            gameDate: PropTypes.string.isRequired,
            salary: PropTypes.number,
            projection: PropTypes.number,
            matchup_projection: PropTypes.number.isRequired,
            ownership: PropTypes.number.isRequired,
            value: PropTypes.number.isRequired,
        }),
    }),
    updateForm: PropTypes.func.isRequired,
    getPlayers: PropTypes.func.isRequired,

    form: PropTypes.shape({
        site: PropTypes.string.isRequired,
        lineups: PropTypes.number.isRequired,
        stackQB: PropTypes.oneOf([false, 'RB', 'TE']),
        stackRB: PropTypes.oneOf([false, 'DST']),
        flexWR: PropTypes.bool.isRequired,
        flexRB: PropTypes.bool.isRequired,
        flexTE: PropTypes.bool.isRequired,
        gameFilter: PropTypes.string.isRequired,
        diversity: PropTypes.number.isRequired,
        exposure: PropTypes.object.isRequired,
        lockExclude: PropTypes.object.isRequired,
    }),
};

const useStyles = makeStyles({
    actionButton: {
        margin: '0 0 0 8px !important',
        minWidth: '80px',
    },
    container: {
        minHeight: '100%',
        padding: '1rem',
        fontSize: '1rem',
    },
});

export const ColorButton = withStyles(theme => ({
    root: {
        color: '#c33',
        backgroundColor: '#fff',
        borderRadius: 50,
        marginTop: '8px !important',
        '&:hover': {
            backgroundColor: '#fdf7f7',
        },
    },
}))(Button);

function AdvanceSettings({
    allPlayers,
    slate,
    site,
    form,
    isLoading,
    isError,
    isSuccess,
    getPlayers,
    updateCustomPoint,
    updateExposure,
    updateLockExclude,
    history,
    isOptimizing,
    optimizeLineup,
    resetPlayers,
    updateForm,
    updateQuickCustomScore,
}) {
    const { reportLineups } = useGoogleAnalytics('/player-settings');
    const { enqueueSnackbar } = useSnackbar();
    const { lockExclude, exposure, customPoints, quickCustomScore } = form;
    const [sort, onSort] = useSorting('points', 'asc');
    const [filter, setFilter] = useState({
        ALL: true,
        QB: false,
        WR: false,
        RB: false,
        DST: false,
        TE: false,
        search: '',
    });
    const [players, setPlayer] = useState(allPlayers);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [isResetDialogVisible, setShowResetDialog] = React.useState(false);
    const [isMembershipTierOpen, setIsMembershipTierOpen] = useState(false);
    const [membershipTierMsg, setMembershipTierMsg] = useState();
    const permissions = usePermissions();

    useOptimize(isOptimizing, history);

    const onOptimize = React.useCallback(
        e => {
            optimizeLineup(form, undefined, allPlayers);
            reportLineups(+form.lineups);
        },
        [form, optimizeLineup, reportLineups, allPlayers]
    );

    const toggleResetPlayerDialog = React.useCallback(() => {
        setShowResetDialog(isVisible => !isVisible);
    }, [setShowResetDialog]);

    const onResetPlayersConfirmed = React.useCallback(() => {
        setShowResetDialog(false);
        resetPlayers();
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 25));
        setPage(0);
    };

    const toggleMembershipDialog = useCallback(
        msg => {
            setIsMembershipTierOpen(open => !open);
            setMembershipTierMsg(typeof msg === 'string' ? msg : '');
        },
        [setIsMembershipTierOpen, setMembershipTierMsg]
    );

    //Fetch the player data based on the slate and site selected.
    useEffect(() => {
        if (!form.gameFilter) {
            history.push('/optimizer/setup');
        } else {
            getPlayers(form.site.toLowerCase(), form.isCaptainMode, form.gameFilter);
        }
    }, [site, slate, form.gameFilter, form.site, form.isCaptainMode]);

    //Filter
    useEffect(() => {
        const searchTerm = filter?.search.toLowerCase();
        let filteredPlayers;
        let players = allPlayers;

        players = players.filter(player => !form.excludeTeams[player.team]);

        if (filter.ALL) {
            if (searchTerm) {
                filteredPlayers = players.filter(player => player.name.toLowerCase().includes(searchTerm));
            } else {
                filteredPlayers = players;
            }
        } else {
            if (searchTerm) {
                filteredPlayers = players.filter(
                    player => filter[player.position] && player.name.toLowerCase().includes(searchTerm)
                );
            } else {
                filteredPlayers = players.filter(player => filter[player.position]);
            }
        }
        setPlayer(filteredPlayers);
    }, [allPlayers, filter, setPlayer, form.excludeTeams, form.gameFilter, enqueueSnackbar, history]);

    //Sort
    useEffect(() => {
        const { sortBy, order } = sort;
        const multiplier = order === 'asc' ? 1 : -1;
        const sortKey = sortBy === 'minExposure' ? 'min' : sortBy === 'maxExposure' ? 'max' : sortBy;
        const isExposure = sortBy === 'minExposure' || sortBy === 'maxExposure';

        setPlayer(players => {
            if (players?.length)
                return players.slice(0).sort((a, b) => {
                    if (isExposure) {
                        const exposureA = (exposure[a.id] || {})[sortKey] || 0;
                        const exposureB = (exposure[b.id] || {})[sortKey] || 0;
                        let sortDirection = (exposureA - exposureB) * multiplier;
                        if (sortDirection === 0) sortDirection = b.projection - a.projection;
                        return sortDirection;
                    } else return (a[sortBy] < b[sortBy] ? -1 : a[sortBy] === b[sortBy] ? 0 : 1) * multiplier;
                });
            return players;
        });
    }, [setPlayer, sort]);

    //Callback for filtering the player list.
    const handleFilter = useCallback(
        e => {
            const name = e.target.name;
            const value = e.target.name === 'search' ? e.target.value : e.target.checked;

            if (name === 'ALL' && value === true) {
                setFilter(prevFilter => ({
                    ALL: true,
                    QB: true,
                    WR: true,
                    RB: true,
                    DST: true,
                    TE: true,
                    search: prevFilter.search,
                }));
            } else if (name !== 'search') {
                setFilter(prevFilter => ({ ...prevFilter, [name]: value, ALL: false }));
            } else {
                setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
            }
        },
        [setFilter]
    );

    const handleRestrictPoolToggle = control => {
        updateForm({
            name: 'ignoreExposureNotLocked',
            value: control.target.checked,
        });
    };

    //Error handling
    useEffect(() => {
        const errors = form.error;
        if (errors) {
            if (errors.form?.length) {
                enqueueSnackbar(`${errors.form.length} errors in line up setup.`, {
                    variant: 'error',
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom',
                    },
                    preventDuplicate: true,
                    action: key => (
                        <Button onClick={() => history.push('/optimizer/setup')} color="inherit">
                            Fix
                        </Button>
                    ),
                });
            }
            if (errors.players?.length) {
                errors.players.forEach(err =>
                    enqueueSnackbar(err.msg, {
                        variant: 'error',
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'bottom',
                        },
                        preventDuplicate: true,
                    })
                );
            }
        }
    }, [form.error, allPlayers, enqueueSnackbar, history]);

    const classes = useStyles();

    return (
        <div style={{ flex: 1, padding: '1rem' }}>
            <MembershipTierDialog
                title="Premium Subscription Required"
                message={membershipTierMsg}
                isOpen={isMembershipTierOpen}
                onCancel={toggleMembershipDialog}
            />

            <Dialog
                open={isResetDialogVisible}
                onClose={toggleResetPlayerDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Reset Player Settings</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to reset your player settings?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleResetPlayerDialog} color="primary">
                        No
                    </Button>
                    <Button onClick={onResetPlayersConfirmed} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <AutoSizer>
                {({ height, width }) => (
                    <BaseCard
                        style={{ height, width }}
                        title="Player Settings"
                        tooltip={tooltip}
                        action={
                            <>
                                {' '}
                                <ColorButton
                                    className={classes.actionButton}
                                    size="small"
                                    variant="contained"
                                    onClick={toggleResetPlayerDialog}
                                >
                                    Reset
                                </ColorButton>{' '}
                                <ColorButton
                                    className={classes.actionButton}
                                    size="small"
                                    variant="contained"
                                    onClick={onOptimize}
                                >
                                    Optimize
                                </ColorButton>{' '}
                            </>
                        }
                    >
                        <FilterRow
                            {...filter}
                            onFilterChanged={handleFilter}
                            updateForm={handleRestrictPoolToggle}
                            ignoreExposureNotLocked={form.ignoreExposureNotLocked}
                        />
                        <TableContainer style={{ maxHeight: height - 96 }}>
                            <Table size="small" stickyHeader>
                                <FantasyTableHeader
                                    headers={headers}
                                    sortBy={sort.sortBy}
                                    sortOrder={sort.order}
                                    onSort={onSort}
                                />
                                <TableBody>
                                    {!isLoading &&
                                        (rowsPerPage > 0
                                            ? players.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : players
                                        ).map(player => (
                                            <PlayerTableRow
                                                player={player}
                                                key={player.id}
                                                max={exposure[player.id]?.max || ''}
                                                min={exposure[player.id]?.min || ''}
                                                isCaptainMode={form.isCaptainMode}
                                                site={form.site}
                                                lockExclude={lockExclude[player.id] || '0'}
                                                onLockChanged={updateLockExclude}
                                                onExposureChanged={updateExposure}
                                                onCustomPointChanged={updateCustomPoint}
                                                customPoints={customPoints[player.id] || ''}
                                                updateQuickCustomScore={updateQuickCustomScore}
                                                quickCustomScore={quickCustomScore[player.id] || 0}
                                                permissions={permissions}
                                                toggleMembershipDialog={toggleMembershipDialog}
                                            />
                                        ))}
                                </TableBody>
                                {players.length > rowsPerPage && (
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                size="small"
                                                rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                                colSpan={13}
                                                count={players.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'rows per page' },
                                                    native: true,
                                                }}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                )}
                            </Table>
                        </TableContainer>
                        {isLoading && <LoadingRow width={width} count={10} />}
                    </BaseCard>
                )}
            </AutoSizer>
        </div>
    );
}

const mapStateToProps = state => {
    const { isLoading, isSuccess, isError, players, site, slate } = state.Player;
    return {
        form: state.Form,
        allPlayers: players || [],
        isLoading,
        isSuccess,
        isError,
        site,
        slate,
        isOptimizing: state.Optimize.isLoading,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getPlayers,
            updateForm,
            updateExposure,
            updateCustomPoint,
            updateLockExclude,
            optimizeLineup,
            resetPlayers,
            updateQuickCustomScore,
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSettings);

const LoadingRow = ({ width, count, ...props }) => (
    <div style={{ width }} {...props}>
        <ContentLoader
            speed={2}
            width={width}
            height={500}
            viewBox={`0 0 ${width} 500`}
            backgroundColor="#f3f3f3"
            foregroundColor="#d1d1d1"
            {...props}
        >
            {[...Array(count)].map((_, index) => (
                <React.Fragment key={index}>
                    <rect x="16" y={12 + 48 * index} rx="5" ry="5" width="50" height="25" />
                    <rect x="82" y={12 + 48 * index} rx="5" ry="5" width="110" height="25" />
                    <rect x="210" y={12 + 48 * index} rx="5" ry="5" width="50" height="25" />
                    <rect x="286" y={12 + 48 * index} rx="5" ry="5" width="120" height="25" />
                    <rect x="440" y={12 + 48 * index} rx="5" ry="5" width="60" height="25" />
                    <rect x="545" y={12 + 48 * index} rx="5" ry="5" width="50" height="25" />
                    <rect x="630" y={12 + 48 * index} rx="5" ry="5" width="70" height="25" />
                    <rect x="740" y={12 + 48 * index} rx="5" ry="5" width="70" height="25" />
                    <rect x="830" y={12 + 48 * index} rx="5" ry="5" width="70" height="25" />
                    <rect x="930" y={12 + 48 * index} rx="5" ry="5" width="70" height="25" />
                    <rect x="1030" y={12 + 48 * index} rx="5" ry="5" width="270" height="25" />
                </React.Fragment>
            ))}
        </ContentLoader>
    </div>
);
