import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    TextField,
    makeStyles,
    Grid,
    FormLabel,
    Slider,
    Dialog,
    DialogTitle,
    Container,
    DialogActions,
    DialogContent,
    Typography,
    InputAdornment,
    Divider,
    Checkbox,
} from '@material-ui/core';

import { FantasyButton } from '../fatnasyButton';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.spacing(2),
        padding: theme.spacing(1),
    },
    checkboxLabel: {
        fontSize: '0.75rem',
    },
    descriptionText: {
        color: '#0000008a',
    },
    input: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

const OwnershipSettingsDialog = props => {
    const {
        initialValues = {},
        onDone,
        onCancel,
        isOpen,
        title,
        stateKey,
        onCheckboxClick,
        enableTeamOwnership,
        enablePlayerOwnership,
        enablePlayerLimits,
        ...rest
    } = props;

    const classes = useStyles();

    const [values, setValues] = useState(initialValues);

    useEffect(() => {
        if (isOpen) setValues(initialValues);
    }, [setValues, isOpen, initialValues]);

    const inputHandlers = useMemo(() => {
        const onSliderChanged = pos => (e, value) => {
            setValues(values => ({ ...values, [pos]: value }));
        };
        return {
            input: e => {
                const { name, value } = e.target;
                setValues(values => ({ ...values, [name]: +value }));
            },
            sliderExcludePlayersAbove: onSliderChanged('excludePlayersAbove'),
            sliderPlayerThreshold: onSliderChanged('playerThreshold'),
            sliderMaxTeamOwnership: onSliderChanged('maxTeamOwnership'),
        };
    }, [setValues]);

    const onSubmit = useCallback(() => {
        onDone({
            [stateKey]: values,
        });
        onCancel();
    }, [onDone, stateKey, values, onCancel]);

    return (
        <Dialog open={isOpen} fullWidth maxWidth="xs" {...rest} classes={{ paper: classes.root }}>
            <DialogTitle>Ownership Settings</DialogTitle>
            <DialogContent>
                <Container fixed maxWidth="md" disableGutters className={classes.root}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={1} style={{ margin: '0 12px 0 -12px' }}>
                            <Checkbox
                                checked={enableTeamOwnership}
                                onChange={onCheckboxClick}
                                name="enableTeamOwnership"
                                color="primary"
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <span>
                                <FormLabel className={classes.checkboxLabel} id="discrete-slider-restrict">
                                    {' '}
                                    Max team ownership{' '}
                                </FormLabel>
                            </span>
                            <Slider
                                defaultValue={500}
                                name={'maxTeamOwnership'}
                                aria-labelledby="discrete-slider-restrict"
                                min={0}
                                max={1000}
                                value={values.maxTeamOwnership}
                                valueLabelDisplay="auto"
                                draggable={true}
                                disabled={!enableTeamOwnership}
                                color="secondary"
                                onChange={inputHandlers.sliderMaxTeamOwnership}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.input}>
                            <TextField
                                name={`maxTeamOwnership`}
                                disabled={!enableTeamOwnership}
                                label="Ownership"
                                type="number"
                                variant="outlined"
                                size="small"
                                value={values.maxTeamOwnership}
                                onChange={inputHandlers.input}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography>%</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={1} style={{ margin: '0 12px 0 -12px' }}>
                            <Checkbox
                                checked={enablePlayerOwnership}
                                onChange={onCheckboxClick}
                                name="enablePlayerOwnership"
                                color="primary"
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <span>
                                <FormLabel className={classes.checkboxLabel} id="discrete-slider-restrict">
                                    {' '}
                                    Max player ownership{' '}
                                </FormLabel>
                            </span>
                            <Slider
                                defaultValue={100}
                                name="excludePlayersAbove"
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={25}
                                max={100}
                                value={values.excludePlayersAbove}
                                valueLabelDisplay="auto"
                                draggable={true}
                                disabled={!enablePlayerOwnership}
                                color="secondary"
                                onChange={inputHandlers.sliderExcludePlayersAbove}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.input}>
                            <TextField
                                name="excludePlayersAbove"
                                disabled={!enablePlayerOwnership}
                                label="Ownership"
                                type="number"
                                variant="outlined"
                                size="small"
                                value={values.excludePlayersAbove}
                                onChange={inputHandlers.input}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography>%</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={12} style={{ marginLeft: '-12px' }}>
                            <Checkbox
                                checked={enablePlayerLimits}
                                onChange={onCheckboxClick}
                                name="enablePlayerLimits"
                                color="primary"
                            />
                            <Typography variant="body" fontWeight={500}>
                                Players Limits
                            </Typography>
                        </Grid>

                        <Grid item xs={8} component={Typography} variant="caption" className={classes.descriptionText}>
                            Limit high ownership players to
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name="playerCount"
                                disabled={!enablePlayerLimits}
                                label="Players"
                                type="number"
                                variant="outlined"
                                size="small"
                                value={values.playerCount}
                                onChange={inputHandlers.input}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <span>
                                <FormLabel className={classes.checkboxLabel} id="discrete-slider-restrict">
                                    {' '}
                                    Max player ownership{' '}
                                </FormLabel>
                            </span>
                            <Slider
                                defaultValue={100}
                                name="playerThreshold"
                                aria-labelledby="discrete-slider-restrict"
                                step={1}
                                min={25}
                                max={100}
                                value={values.playerThreshold}
                                valueLabelDisplay="auto"
                                draggable={true}
                                disabled={!enablePlayerLimits}
                                color="secondary"
                                onChange={inputHandlers.sliderPlayerThreshold}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.input}>
                            <TextField
                                name="playerThreshold"
                                disabled={!enablePlayerLimits}
                                label="Ownership"
                                type="number"
                                variant="outlined"
                                size="small"
                                value={values.playerThreshold}
                                onChange={inputHandlers.input}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography>%</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={12} justify="space-evenly">
                    <Grid item xs={5} justify="center">
                        <FantasyButton label="Cancel" variant="outlined" fullWidth onClick={onCancel} />
                    </Grid>
                    <Grid item xs={5}>
                        <FantasyButton label="Okay" variant="contained" onClick={onSubmit} fullWidth />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default OwnershipSettingsDialog;
