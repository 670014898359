import React from 'react';
import {
    TextField,
    makeStyles,
    InputAdornment,
    Container,
    Grid,
    Button,
    CircularProgress,
    Typography,
} from '@material-ui/core';
import { PermIdentityOutlined as UserIcon, VpnKeyOutlined as PasswordIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import FantasyButton from '../fatnasyButton';
import logo from '../../assets/img/dfs_square_logo.png';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    form: {
        width: '100%',
    },
    input: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
        alignContent: 'center',
        width: 150,
    },
    icon: {
        color: 'rgba(0, 0, 0, 0.25)',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '20%',
        marginTop: -4,
    },
    supportLink: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: theme.palette.primary.main,
        textDecoration: 'unset',
        letterSpacing: '2px',
        fontWeight: '700 !important',
    },
}));

/**
 * Login view component.
 */
function LoginForm({
    onInputChange,
    onSubmit,
    onRegister,
    onForgotPassword,
    email,
    password,
    error = {},
    isLoading,
    ...rest
}) {
    const classes = useStyles();
    useGoogleAnalytics('/optimizer/login');

    function sendInputUpdate(e) {
        onInputChange({ email, password, [e.target.name]: e.target.value });
    }

    return (
        <Container maxWidth="xs" style={{ height: '-webkit-fill-available' }} {...rest}>
            <div className={classes.paper}>
                <img src={logo} width="300" alt="Fantasy Point Generator Logo" />
                <form className={classes.form}>
                    <TextField
                        className={classes.input}
                        name="email"
                        id="email"
                        variant="outlined"
                        placeholder="Email"
                        type="email"
                        fullWidth
                        size="small"
                        value={email}
                        onChange={sendInputUpdate}
                        helperText={error.email}
                        error={!!error.email}
                        disabled={isLoading}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <UserIcon className={classes.icon} scale="6" />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        className={classes.input}
                        name="password"
                        id="password"
                        placeholder="Password"
                        variant="outlined"
                        type="password"
                        fullWidth
                        size="small"
                        disabled={isLoading}
                        value={password}
                        onChange={sendInputUpdate}
                        helperText={error.password}
                        error={!!error.password}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PasswordIcon className={classes.icon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FantasyButton
                                onClick={onRegister}
                                disabled={isLoading}
                                variant="outlined"
                                label="Register"
                                fullWidth={true}
                                className={classes.button}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ position: 'relative' }}>
                            <FantasyButton
                                onClick={onSubmit}
                                disabled={isLoading}
                                variant="contained"
                                label="Login"
                                fullWidth={true}
                                className={classes.button}
                            />
                            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={onForgotPassword} variant="text" color="primary">
                                Forgot Password
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>

            <Typography
                className={classes.supportLink}
                variant="caption"
                color="primary"
                component="a"
                target="_blank"
                href="https://support.fantasypoints.com//hc/en-us/requests/new?ticket_form_id=360001131571"
            >
                FEEDBACK / SUPPORT
            </Typography>
        </Container>
    );
}

LoginForm.propTypes = {
    onInputChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onRegister: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    error: PropTypes.shape({
        email: PropTypes.string,
        password: PropTypes.string,
    }),
};
export default LoginForm;
