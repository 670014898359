import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FantasyButton } from '../../components/fatnasyButton';

ErrorDialog.propTypes = {
    error: PropTypes.string,
    onClickFix: PropTypes.func,
};

function ErrorDialog({ error, onClickFix }) {
    return (
        <Dialog
            open={!!error}
            onClose={onClickFix}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">No Feasible Lineups</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{error}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <FantasyButton label="Fix" onClick={onClickFix} color="primary" variant="contained" />
            </DialogActions>
        </Dialog>
    );
}

export default ErrorDialog;
