import React from 'react';
import axios from 'axios';
import { Snackbar } from '@material-ui/core';

export const redirectToLogin = () => {
    // clear the local storage
    localStorage.clear();
    // clear the local cookies
    document.cookie.split(';').forEach(c => {
        document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    // redirect to the login screen
    if (!window.location.pathname === '/login') window.location.href = '/';
};

export default {
    setupInterceptors: () => {
        // Add a response interceptor
        axios.interceptors.response.use(
            response => {
                if (
                    response.data.message === 'AUTH_TOKEN_NOT_VALID' ||
                    response.status === 401 ||
                    response.status === 403
                )
                    redirectToLogin();
                return response;
            },
            error => {
                let err = JSON.parse(JSON.stringify(error));
                if (err.message === 'Network Error') {
                    return (
                        <>
                            <Snackbar
                                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                                message="Network Error"
                            />
                        </>
                    );
                }
                err = JSON.parse(JSON.stringify(error.response));
                if (err.data.message === 'AUTH_TOKEN_NOT_VALID' || err.status === 401 || err.status === 403)
                    redirectToLogin();
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(error.response);
            }
        );
    },
};
//<Alert message="Network Error" type="error" showIcon />
