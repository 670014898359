import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './redux/store/configure-store';
import { setAuthToken, isLoggedIn } from './services/auth';
import NetworkService from './redux/networkservice/networkService';
// import "antd/dist/antd.css";
import './assets/scss/index.scss';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core';
import theme from './containers/ThemeProvider';
import { SnackbarProvider } from 'notistack';

const store = configureStore();
if (isLoggedIn()) {
    setAuthToken();
}

NetworkService.setupInterceptors(store);
ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={5}>
                    <App />
                </SnackbarProvider>
            </ThemeProvider>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
