/* eslint-disable consistent-return */
import axios from 'axios';
import { setAuthToken } from '../../services/auth';
import { onLogout } from '../../services/auth';

export default function axiosCall(method, url, responseType, data) {
    return async dispatch => {
        await setAuthToken();
        const apiData = data ? { method, url, data, query: method === 'get' ? data : undefined } : { method, url };
        dispatch({
            type: `${responseType}_LOADING`,
            updatePayload: data,
        });
        return axios(apiData)
            .then(response => {
                if (response.data) {
                    if (response.data.res?.isWaiting) {
                        dispatch({ type: `${responseType}_WAITING`, updatePayload: response.data });
                    } else {
                        dispatch({ type: `${responseType}_SUCCESS`, updatePayload: response.data });
                    }
                    return response.data;
                }
            })
            .catch(err => {
                if (err.status === 403) onLogout();
                console.log(err);
                dispatch({
                    type: `${responseType}_REJECTED`,
                    updatePayload: err.data,
                });
                throw err.data;
            });
    };
}
