import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function NavBarLink(props) {
    const { linkTo, active, label, ...rest } = props;

    return (
        <Link to={linkTo} {...rest} className={'nav-link ' + (active ? 'nav-link-active' : 'menu-link')}>
            {label}
            <div className={active ? 'nav-is-selected' : ''}></div>
        </Link>
    );
}

NavBarLink.propTypes = {
    linkTo: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
};

export default NavBarLink;
