import types from '../actions/action-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const DEFAULT_STATE = {
    isError: false,
    isLoading: false,
    res: [],
    isSuccess: false,
    site: '',
    slate: '',
    players: [],
};
export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case `${types.PLAYERS}_LOADING`:
            return {
                ...DEFAULT_STATE,
                ...action.updatePayload,
                isLoading: true,
            };
        case `${types.PLAYERS}_REJECTED`:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case `${types.PLAYERS}_SUCCESS`:
            const players = action.updatePayload.res;
            players.forEach(player => {
                const parsedDate = dayjs.utc(player.gameDate);
                player.date = parsedDate.unix();
                player.displayDate = parsedDate.utc().format('M/D @ hh:mm a');
                player.projection = +player.points || 0;
                player.value = (player.projection * 1000) / player.salary;
                player.ownership = +player.ownership || 0;
            });
            return {
                ...state,
                isLoading: false,
                ...action.updatePayload,
                players: players,
                isSuccess: true,
            };

        default:
            return state;
    }
};
