import React, { useCallback, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    Container,
    makeStyles,
    TextField,
    CircularProgress,
} from '@material-ui/core';
import { FantasyButton } from '../fatnasyButton';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.spacing(2),
        padding: theme.spacing(1),
    },
    descriptionText: {
        color: '#0000008a',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        bottom: '50%',
        left: '0.5rem',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
}));

const SaveConfigDialog = ({ isOpen, onCancel, onSave, isLoading = true, isError, errorMessage, ...rest }) => {
    const ref = React.useRef(false);
    const { enqueueSnackbar } = useSnackbar();

    const [configName, setConfigName] = React.useState('');

    const handleConfigNameChange = useCallback(
        e => {
            setConfigName(e.target.value);
        },
        [setConfigName]
    );

    const handeOnSave = useCallback(() => {
        if (configName === '') {
            enqueueSnackbar('Please provide a configuration name', {
                variant: 'error',
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom',
                },
            });
            return;
        }
        ref.current = true;
        onSave(configName);
    }, [onSave, configName, enqueueSnackbar]);

    useEffect(() => {
        if (ref.current === isLoading) return;
        if (ref.current) {
            if (isError) {
                enqueueSnackbar(errorMessage, { variant: 'error' });
            } else {
                enqueueSnackbar('Configuration saved successfully', {
                    variant: 'success',
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'bottom',
                    },
                });
                onCancel();
            }
            ref.current = false;
        }
    }, [isLoading, isError, errorMessage, enqueueSnackbar, onCancel]);

    const classes = useStyles();
    return (
        <Dialog open={isOpen} fullWidth maxWidth="xs" {...rest} classes={{ paper: classes.root }}>
            <DialogTitle>Saved Settings</DialogTitle>
            <DialogContent>
                <Container fixed maxWidth="md" disableGutters className={classes.root}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <DialogContentText className={classes.descriptionText}>
                                Save your current ownership settings as a new configuration. You can load this
                                configuration at any time.
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Configuration Name"
                                variant="outlined"
                                disabled={isLoading}
                                value={configName}
                                onChange={handleConfigNameChange}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={12} justify="space-evenly">
                    <Grid item xs={5} justify="center">
                        <FantasyButton
                            label="Cancel"
                            variant="outlined"
                            fullWidth
                            onClick={onCancel}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={5} style={{ position: 'relative' }}>
                        <FantasyButton
                            label="Okay"
                            variant="contained"
                            onClick={handeOnSave}
                            fullWidth
                            disabled={isLoading}
                        />
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default SaveConfigDialog;
