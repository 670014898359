import URL from '../../config/routes';
import types from './action-types';
import axiosCall from './index';

/**
 * Get a list of games.
 */
export function getGames(site, isCaptainMode, slate) {
    const path = `${URL.GET_GAMES}?site=${site}&slate=${slate}&isCaptainMode=${isCaptainMode}`;
    const responseType = types.GAMES;
    return axiosCall('get', path, responseType, { site, isCaptainMode, slate });
}

/**
 * Get a list of games.
 */
export function getPlayers(site, isCaptainMode, slate) {
    const path = `${URL.GET_PLAYERS}?site=${site}&slate=${slate}&isCaptainMode=${isCaptainMode}&gameId=1`;
    const responseType = types.PLAYERS;
    return axiosCall('get', path, responseType);
}

export function getSlates(site, isCaptainMode) {
    const path = `${URL.GET_SLATES}?site=${site}&isCaptainMode=${isCaptainMode}`;
    const responseType = types.SLATES;
    return axiosCall('get', path, responseType, { site, isCaptainMode });
}

/**
 *
 * @param {object} form
 * @param {'yahoo'|'fanduel'|'draftking'} form.site
 * @param {number} form.lineups
 * @param {{RB:boolean, WR:boolean, TE:boolean}} form.flex
 * @param {number} form.diversity
 * @param {false|'WR'|'TE'} form.qbStack
 * @param {false|'DST'} form.rbStack
 * @param {string} form.gameFilter
 * @param {{[string]:{min:number, max:number}}} exposure
 * @param {{[string]:boolean}} excludeTeam
 * @param {{[string]:-1|0|1}} form.lockExclude
 */
export function optimizeLineup(form, games, players, permissions) {
    const { errors, payload } = getOptimizationBody(form, players, games, permissions);
    if (errors) {
        return dispatch =>
            dispatch({
                type: `${types.FORM}_ERROR`,
                updatePayload: errors,
            });
    }
    return axiosCall('post', URL.POST_OPTIMIZE, types.OPTIMIZE, payload);
}

export function saveConfig(configName, form, games, players) {
    const { errors, payload } = getOptimizationBody(form, players, games);
    if (errors) {
        return dispatch =>
            dispatch({
                type: `${types.CONFIG_SAVE}_ERROR`,
                updatePayload: errors,
            });
    }
    return axiosCall('post', URL.CONFIG, types.CONFIG_SAVE, { name: configName, config: payload });
}

export function loadConfig(site, isCaptainMode) {
    const path = `${URL.CONFIG}/site/${site}?isCaptainMode=${+isCaptainMode}`;
    const responseType = types.CONFIG_GET;
    return axiosCall('get', path, responseType);
}

export function loadConfigById(id) {
    return axiosCall('get', `${URL.CONFIG}/${id}`, types.CONFIG_GET);
}

export function loadAllConfigs(site, isCaptainMode) {
    const path = `${URL.CONFIG}?site=${site}&isCaptainMode=${+isCaptainMode}`;
    return axiosCall('get', path, types.CONFIG_GET_ALL);
}

/**
 *
 * @param {*} form
 * @param {*} players
 * @param {*} games
 * @returns {{errors:{form:[],players:[]}, payload:*}}
 */
function getOptimizationBody(form, allPlayers, games, permissions = {}) {
    const {
        site,
        lineups,
        stackQB,
        stackRB,
        flexTE,
        flexRB,
        flexWR,
        ignoreExposureNotLocked,
        diversity,
        gameFilter,
        excludeTeams,
        exposure,
        lockExclude,
        isCaptainMode,
        randomize,
        randomizeOpts,
        customPoints,
        enablePosExposure,
        posExposure,
        stacks,
        quickCustomScore,
        enableOwnershipSettings,
        ownership,
        enableTeamOwnership,
        enablePlayerOwnership,
        enablePlayerLimits,
        enableFlexExposure,
        flexExposure,
    } = form;
    const errors = { form: [], players: [] };

    //converting customPoints object in required form

    let customPointsPayLoad = [];
    Object.keys(customPoints).forEach(key => {
        if (key && customPoints[key] !== undefined) customPointsPayLoad.push({ id: key, points: customPoints[key] });
    });
    //Flex
    if (!flexTE && !flexRB && !flexWR && !isCaptainMode) {
        errors.form.push({ msg: 'Please select a flex position.' });
    }

    //Lineup limit
    const maxLineups = permissions.maxLineups || 150;
    const intLineup = +lineups || 0;
    if (intLineup < 1 || intLineup > maxLineups || Math.floor(intLineup) !== intLineup) {
        errors.form.push({ key: 'lineups', msg: 'Line up counts must be between 1 and ' + maxLineups + '.' });
    }

    const exposures = getExposure(exposure, diversity);

    const { includePlayer, excludePlayer, captainPlayer } = getIncludedExcludedPlayers(lockExclude);

    //Clean up stacks
    // const weight = stacks.reduce((cum, cur) => +cur.weight + cum, 0);
    const newStack = stacks.map(stack => {
        return {
            include: (stack.keyPlayer ? [stack.keyPlayer] : []).concat(stack.included).map(player => player.id),
            exclude: stack.excluded.map(player => player.id),
            weight: stack.weight,
        };
    });

    // if(stacks.length && ( weight !== 100)){
    // 	errors.form.push({msg: 'Custom stack weight must sum to 100%.'});
    // }
    if (stacks.some(stack => +stack.weight < 0)) {
        errors.form.push({ msg: 'Custom stack weight can not be negative.' });
    }
    if (stacks.some(stack => stack.weight === '0' || stack.weight === '')) {
        errors.form.push({ msg: 'Please assign weights to all stacks.' });
    }
    if (stacks.some(stack => !stack?.keyPlayer?.id)) {
        errors.form.push({ msg: 'Please select a key player for all stacks.' });
    }
    if (stacks.some(stack => stack?.included.length + stack?.excluded.length === 0)) {
        errors.form.push({ msg: 'Please select at least one player to stack with the key player for all stacks.' });
    }

    //creating bump payload
    let bumpPayLoad = [];
    Object.keys(quickCustomScore).forEach(id => bumpPayLoad.push({ id: id, boost: quickCustomScore[id] }));

    //creating flex Exposure Payload
    let { custom, GLOBAL, ...flexExposurePayload } = flexExposure;
    if (!custom) {
        flexExposurePayload = {
            RB: GLOBAL,
            WR: GLOBAL,
            TE: GLOBAL,
        };
    }

    //Validate included players

    const posValidationErrors = isCaptainMode
        ? validateCaptainModeCounts(site, includePlayer, captainPlayer)
        : validatePositionCounts({ RB: flexRB, WR: flexWR, TE: flexTE }, lockExclude, allPlayers);
    if (posValidationErrors) {
        errors.players = errors.players.concat(posValidationErrors);
    }

    //Validate exposure
    exposures.some(exposure => {
        if (exposure.min > exposure.max) {
            const errorPlayer = allPlayers.find(player => player.id === exposure.id);
            errors.players.push({
                id: exposure.id,
                msg: `Invalid min/max exposure for ${errorPlayer.name} - ${errorPlayer.team}`,
            });
            return true;
        }
        return false;
    });

    //Validate Ownership
    const tmpOwnership = {};
    if (enableOwnershipSettings) {
        if (enableTeamOwnership) {
            tmpOwnership.maxTeamOwnership = ownership.maxTeamOwnership;
        }
        if (enablePlayerOwnership) {
            tmpOwnership.excludePlayersAbove = ownership.excludePlayersAbove;
        }
        if (enablePlayerLimits) {
            tmpOwnership.playerCount = ownership.playerCount;
            tmpOwnership.playerThreshold = ownership.playerThreshold;
        }
    }

    if (errors.form.length > 0 || errors.players.length > 0) {
        return { errors };
    } else {
        return {
            errors: false,
            payload: {
                site,
                ...(isCaptainMode ? {} : { flex: { TE: flexTE, RB: flexRB, WR: flexWR }, stackQB, stackRB }),
                lineups,
                slate: gameFilter,
                excludeTeams: Object.keys(excludeTeams).filter(team => excludeTeams[team]),
                captain: captainPlayer.length > 0 ? captainPlayer[0] : undefined,
                includePlayer,
                excludePlayer,
                exposure: exposures,
                diversity,
                ignoreExposureNotLocked,
                isCaptainMode,
                randomize,
                randomizeOpts: {
                    QB: randomizeOpts.custom ? randomizeOpts.QB : randomizeOpts.GLOBAL,
                    RB: randomizeOpts.custom ? randomizeOpts.RB : randomizeOpts.GLOBAL,
                    WR: randomizeOpts.custom ? randomizeOpts.WR : randomizeOpts.GLOBAL,
                    TE: randomizeOpts.custom ? randomizeOpts.TE : randomizeOpts.GLOBAL,
                    DST: randomizeOpts.custom ? randomizeOpts.DST : randomizeOpts.GLOBAL,
                },
                customPoints: customPointsPayLoad,
                posExposure: {
                    QB: posExposure.custom ? posExposure.QB : posExposure.GLOBAL,
                    RB: posExposure.custom ? posExposure.RB : posExposure.GLOBAL,
                    WR: posExposure.custom ? posExposure.WR : posExposure.GLOBAL,
                    TE: posExposure.custom ? posExposure.TE : posExposure.GLOBAL,
                    DST: posExposure.custom ? posExposure.DST : posExposure.GLOBAL,
                },
                enablePosExposure,
                stack: newStack,
                bumps: bumpPayLoad,
                ...(isCaptainMode ? {} : { flexExposure: flexExposurePayload, enableFlexExposure: enableFlexExposure }),
                ownership: { ...tmpOwnership },
            },
        };
    }
}

/**
 *
 * @param {'Fanduel'|'DraftKings'} site
 * @param {PartialPlayer[]} allPlayers
 * @param {String[]} includePlayers
 * @param {String[]} excludePlayers
 * @param {String[]} captainPlayers
 */
function validateCaptainModeCounts(site, includePlayers, captainPlayers) {
    const errors = [];
    const lockedPlayerCount = (captainPlayers.length ? 1 : 0) + includePlayers.length;

    if (captainPlayers.length > 1) errors.push({ key: 'lockExclude', msg: `There can only be one Captain/MVP.` });

    if (site === 'Fanduel' && lockedPlayerCount > 5) {
        errors.push({ msg: `Team size cannot exceed 5.` });
    } else if (site === 'DraftKings' && lockedPlayerCount > 6) {
        errors.push({ msg: `Team size cannot exceed 6.` });
    }

    return errors;
}

/**
 * Convert the exposure map to exposure Array with defaults.
 * @param {{[string]:{min:number, max:number}}} exposures
 * @returns {{id:string, min:number, max:number}[]}
 */
function getExposure(exposures, diversity) {
    const globalMin = 0;
    const globalMax = (5 - +diversity + 1) * 20;

    return Object.keys(exposures).map(id => {
        const exposure = exposures[id];
        return {
            id,
            min: (+exposure.min || globalMin) / 100,
            max: (+exposure.max || globalMax) / 100,
        };
    });
}

/**
 * Create a included and excluded array fron lockExclude map.
 * @param {{[string]:-1|0|1}} lockExclude
 * @returns {{includePlayer:string[], excludePlayer:string[], captainPlayer:string[]}}
 */
function getIncludedExcludedPlayers(lockExclude) {
    const includePlayer = [];
    const excludePlayer = [];
    const captainPlayer = [];
    Object.keys(lockExclude).forEach(id => {
        const value = +lockExclude[id];
        id = +id;
        if (value === 1) {
            includePlayer.push(id);
        } else if (value === -1) {
            excludePlayer.push(id);
        } else if (value === 2) {
            captainPlayer.push(id);
        }
    });
    return { includePlayer, excludePlayer, captainPlayer };
}

/**
 *
 * @param {Flex} flex
 * @param {{[string]:"1"|"0"|"-1"}} includeExclude
 * @param {PartialPlayer[]} allPlayers
 */
function validatePositionCounts(flex, includeExclude, allPlayers) {
    if (!allPlayers) return;
    const maxCounts = {
        QB: 1,
        RB: 2 + (flex.RB ? 1 : 0),
        WR: 3 + (flex.WR ? 1 : 0),
        TE: 1 + (flex.TE ? 1 : 0),
        DST: 1,
    };
    const positionCounts = getPositionCounts(includeExclude, allPlayers);
    const errors = [];
    Object.keys(maxCounts).forEach(pos => {
        if (positionCounts[pos] > maxCounts[pos]) {
            errors.push({ key: 'lockExclude', msg: `${pos} - Max locked ${pos}s cannot exceed ${maxCounts[pos]}.` });
        }
    });

    const posRBWR = positionCounts.RB + positionCounts.WR;
    const posRBTE = positionCounts.RB + positionCounts.TE;
    const posWRTE = positionCounts.WR + positionCounts.TE;
    const flexCounts = positionCounts.RB + positionCounts.TE + positionCounts.WR;
    const maxRBTE = 3 + (flex.RB || flex.TE ? 1 : 0);
    const maxWRTE = 4 + (flex.WR || flex.TE ? 1 : 0);
    const maxRBWR = 5 + (flex.RB || flex.WR ? 1 : 0);

    if (flexCounts > 7) {
        errors.push({ msg: 'Number of players locked RB, WR and TE should not exceed 7.' });
    } else if (posRBTE > maxRBTE) {
        errors.push({ msg: `Number of players locked RB and TE should not exceed ${maxRBTE}.` });
    } else if (posRBWR > maxRBWR) {
        errors.push({ msg: `Number of players locked RB and WR should not exceed ${maxRBWR}.` });
    } else if (posWRTE > maxWRTE) {
        errors.push({ msg: `Number of players locked RB and TE should not exceed ${maxWRTE}.` });
    }

    if (errors.length) return errors;
}

/**
 *
 * @param {*} includeExclude
 * @param {PartialPlayer[]} allPlayers
 */
function getPositionCounts(includeExclude, allPlayers) {
    const constrainedPlayers = allPlayers.filter(player => includeExclude[player.id] == 1);
    const positions = { QB: 0, RB: 0, WR: 0, TE: 0, DST: 0 };
    constrainedPlayers.forEach(player => positions[player.position]++);
    return positions;
}

/**
 * @typedef Flex
 * @property {boolean} RB
 * @property {boolean} WR
 * @property {boolean} TE
 */

/**
 * @typedef PartialPlayer
 * @property {number} id
 * @property {'QB'|'RB'|'WR'|'TE'|'DST'} position
 * @property {String} team
 */
