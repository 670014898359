import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

Timer.propTypes = {
    countodwnTime: PropTypes.number.isRequired,
    onCountdownFinished: PropTypes.func,
};

function Timer({ countodwnTime, onCountdownFinished, ...rest }) {
    const [time, setTime] = useState(getDisplayTime(countodwnTime));

    useEffect(() => {
        if (time.remaining <= 0) return;

        const timeOut = setTimeout(() => {
            time.remaining--;

            if (time.remaining <= 0 && onCountdownFinished) onCountdownFinished();
            setTime(getDisplayTime(time.remaining));
        }, 1000);
        return () => clearTimeout(timeOut);
    }, [time, setTime, onCountdownFinished]);

    return (
        <Typography variant="body1" component="div" {...rest}>
            {time.minutes.toString().padStart(2, '0')}:{time.second.toString().padStart(2, '0')}
        </Typography>
    );
}

function getDisplayTime(timeRemaining) {
    timeRemaining = Math.round(timeRemaining, 0);
    return {
        remaining: timeRemaining,
        minutes: Math.floor(timeRemaining / 60, 0),
        second: timeRemaining % 60,
    };
}

export default Timer;
