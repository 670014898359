import React from 'react';
import PropTypes from 'prop-types';
import {
    FormGroup,
    FormControl,
    FormControlLabel,
    Checkbox,
    InputAdornment,
    Grid,
    TextField,
    makeStyles,
} from '@material-ui/core';

import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '../../components/tooltip';
import { InfoRounded as Info } from '@material-ui/icons';

const checkBoxLabels = [
    { key: 'ALL', label: 'ALL' },
    { key: 'QB', label: 'QB' },
    { key: 'RB', label: 'RB' },
    { key: 'WR', label: 'WR' },
    { key: 'TE', label: 'TE' },
    { key: 'DST', label: 'DST' },
];

const useStyle = makeStyles(theme => ({
    filterContainer: {
        background: '#f5d6d6',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    filterGroup: {
        marginLeft: theme.spacing(1),
    },
    exlcludeLock: {
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
        },
    },
    alignTooltip: {
        display: 'flex',
        alignItems: 'center',
    },
}));

function FilterRow({ search, onFilterChanged, updateForm, ...props }) {
    const classes = useStyle();
    return (
        <>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={classes.filterContainer}
            >
                <Grid item container alignItems="center" className="filter-item" xs={12} md={5} lg={4}>
                    <FilterListIcon />
                    <FormControl component="fieldset" className={classes.filterGroup}>
                        <FormGroup aria-label="position" row>
                            {checkBoxLabels.map(({ key, label }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            size="small"
                                            checked={props[key]}
                                            onClick={onFilterChanged}
                                            name={key}
                                        />
                                    }
                                    label={label}
                                    labelPlacement="end"
                                    key={key}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item container alignItems="center" className={classes.exlcludeLock} xs={12} md={4} lg={4}>
                    <FormControl component="fieldset" className={classes.filterGroup}>
                        <FormGroup aria-label="position" row className={classes.alignTooltip}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        size="small"
                                        checked={props.ignoreExposureNotLocked}
                                        onClick={updateForm}
                                        name="ignoreExposureNotLocked"
                                        value={props.ignoreExposureNotLocked}
                                    />
                                }
                                label="Limit pool to exposure/lock players"
                                labelPlacement="end"
                            />
                            <Tooltip
                                style={{ marginLeft: '-10px' }}
                                title={`For advanced users only - include only those players you either locked or set exposure limits for.`}
                            >
                                <Info fontSize="small" color="inherit" />
                            </Tooltip>
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} lg={4}>
                    <TextField
                        type="text"
                        className="filter-search"
                        name="search"
                        placeholder="Filter by Name"
                        value={search}
                        onChange={onFilterChanged}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}

FilterRow.propTypes = {
    ALL: PropTypes.bool.isRequired,
    QB: PropTypes.bool.isRequired,
    WR: PropTypes.bool.isRequired,
    RB: PropTypes.bool.isRequired,
    TE: PropTypes.bool.isRequired,
    DST: PropTypes.bool.isRequired,
    search: PropTypes.string,
    onFilterChanged: PropTypes.func,
};
export default React.memo(FilterRow);
